import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-equipe',
  templateUrl: './equipe.component.html',
  styleUrls: ['./equipe.component.css']
})
export class EquipeComponent implements OnInit {
  public lottieConfig: Object;
  constructor() { 

    this.lottieConfig = {
      path: 'https://assets3.lottiefiles.com/packages/lf20_uAc5ki.json',
      renderer: 'canvas',
      autoplay: true,
      loop: true
    };
  }
  
  ngOnInit() {


  }

}
