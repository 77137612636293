import { Component, OnInit } from '@angular/core';
import { StripeService, Elements, Element as StripeElement, ElementsOptions } from "ngx-stripe";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductsService } from '../services/products.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  paymentsubmit: boolean = false
  infoprofile: any
  billingAdress: FormGroup
  submitted = false;
  message: any[];
  elements: Elements;
  cardNumber: StripeElement;
  cardExpiry: StripeElement;
  cardCvc: StripeElement;
  errMsgCheckout: string;
  checknoticketchoice: any = false // on vérifie qu'il y a des tickets pour afficher le systeme de paiement et la mise à jour des informations - true = il n'y pas de ticket

  // optional parameters
  elementsOptions: ElementsOptions = {
    locale: 'en'
  };

  stripeTest: FormGroup;

  constructor(
    private fb: FormBuilder,
    private stripeService: StripeService,
    public productservice: ProductsService,
    private formBuilder: FormBuilder,
    public cookieservice: CookieService,
    private router: Router,
    private snackBar: MatSnackBar) { }

  ngOnInit() {

    this.productservice.getcookieconnect()
    if (this.cookieservice.get('email') != "") {
      this.productservice.user_login(this.productservice.login)
    }

    // On vérifie que l'utilisateur est connecté, sinon on le renvoi vers la page de connexion/inscription
    if (this.cookieservice.get('email') == "") {
      this.router.navigateByUrl('/account');
    }

    if (localStorage.ticketChoice.length <= 2) {
      this.router.navigateByUrl('/select');
      this.snackBar.open('You have to add at least 1 ticket to join the competition', 'Close');
    }

    this.billingAdress = this.formBuilder.group({
      lastname: ['', [Validators.required]],
      firstname: ['', [Validators.required]],
      adressline1: ['', [Validators.required]],
      adressline2: [''],
      town: ['', [Validators.required]],
      postcode: ['', [Validators.required]]
    });

    // this.billingAdress.patchValue({
    //   name: "gdf"
    // })

    // if(this.cookieservice.get('email') != ""){
    //   this.productservice.user_login(this.productservice.login)
    // }

    this.productservice.infoMessage.subscribe(info => {
      Object.keys(info).forEach((key) => (info[key] == null) && delete info[key]);

      Object.keys(info).forEach(name => {
        if (this.billingAdress.controls[name]) {
          this.billingAdress.controls[name].patchValue(info[name], { onlySelf: false });
        }
      });


    })




    this.productservice.basketsummarylist.subscribe((message) => {
      this.message = message;

      if (message !== undefined && message.length != 0) {
        this.productservice.orderSummary = message[message.length - 1].basketTotal
        this.productservice.orderSummaryTotalTicket = message[message.length - 1].totalticket
      }
      // console.log(message)
    })


    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]]
    });
    this.stripeService.elements(this.elementsOptions)
      .subscribe(elements => {
        this.elements = elements;
        // Only mount the element the first time


        this.cardNumber = this.elements.create('cardNumber', {
        });
        this.cardNumber.mount('#example3-card-number');

        this.cardExpiry = this.elements.create('cardExpiry', {
        });
        this.cardExpiry.mount('#example3-card-expiry');

        this.cardCvc = this.elements.create('cardCvc', {
        });
        this.cardCvc.mount('#example3-card-cvc');






      });
  }

  buy() {
    this.productservice.paymentsubmit = true
    this.submitted = true;
    // stop here if form is invalid
    console.log(this.billingAdress)
    if (this.billingAdress.invalid) {
      this.errMsgCheckout = "Please fill in your contact details"
      this.productservice.paymentsubmit = false
      return;
    }
    // console.log(this.card)
    console.log(this.cardCvc)
    const name = this.stripeTest.get('name').value;
    this.stripeService
      .createToken(this.cardNumber, { name })
      .subscribe(result => {

        if (result.token) {
          // Use the token to create a charge or a customer
          // https://stripe.com/docs/charges

          this.productservice.payment(result.token, this.billingAdress.getRawValue())
          this.errMsgCheckout = "undefined"
        } else if (result.error) {
          // Error creating the token
          // console.log(result.error.message);
          this.errMsgCheckout = result.error.message
          this.productservice.paymentsubmit = false
        }


      });
  }

  qsdf() {
    console.log(JSON.parse(localStorage.basket))
  }

  get f() { return this.billingAdress.controls; }



}
