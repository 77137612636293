import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-exchange',
  templateUrl: './exchange.component.html',
  styleUrls: ['./exchange.component.css']
})
export class ExchangeComponent implements OnInit {
  public lottieConfig: Object;
  constructor(
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: any
  ) { 
    this.lottieConfig = {
      path: 'https://assets8.lottiefiles.com/packages/lf20_L6eLYC.json',
      renderer: 'canvas',
      autoplay: true,
      loop: true
    };

  }

  conditiontr: any
  ngOnInit() {
    this.conditiontr = this.document.location.href.indexOf("en.gigatree") > -1
    if (this.conditiontr == true) {
      this.translate.setDefaultLang('en');
      this.translate.use('en');
      console.log("en")
    } else {
      this.translate.setDefaultLang('fr');
      this.translate.use('fr');
      console.log("fr")
    }
  }

}
