import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { AuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider, SocialUser } from "angularx-social-login";
import { HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ProductsService } from '../services/products.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { MatSnackBar } from '@angular/material';
import { AnonymousSubject } from 'rxjs/internal/Subject';


@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  loginForm: FormGroup;
  registerForm: FormGroup;
  submitted = false;
  submittedRegister = false;
  infoprofile: any
  orderdetails: any[] = []
  spinner: any = false
  amountToSend: any
  walletAmount: any = 20000
  walletAddress: any
  showSecretKey: any = false
  showWallet: any
  toPublicKey: any


  public lottieConfig: Object;
  private anim: any;
  private animationSpeed: number = 1;

  constructor(

    @Inject(PLATFORM_ID) private platformId: any,
    public authService: AuthService,
    public productservice: ProductsService,
    private formBuilder: FormBuilder,
    public cookieService: CookieService,
    private snackBar: MatSnackBar) {
    this.lottieConfig = {
      path: 'https://assets4.lottiefiles.com/packages/lf20_U37p5l.json',
      renderer: 'canvas',
      autoplay: true,
      loop: false
    };
  }

  handleAnimation(anim: any) {
    this.anim = anim;
  }

  sendTransaction() {
    this.productservice.sendTransaction(this.productservice.walletPublic, this.productservice.walletSecret, this.amountToSend, this.toPublicKey)
  }

  stop() {
    this.anim.stop();
  }
  setTwoNumberDecimal($event) {
    $event.target.value = parseFloat($event.target.value).toFixed(8);
    this.amountToSend = parseFloat($event.target.value).toFixed(8);
  }
  play() {
    this.anim.play();
  }


  pause() {
    this.anim.pause();
  }

  setSpeed(speed: number) {
    this.animationSpeed = speed;
    this.anim.setSpeed(speed);
  }

  test(yyyf) {
    console.log(yyyf)
    console.log(parseFloat(yyyf).toFixed(8))

  }

  superfunc() {
    console.log("qsdf")
    const element = document.getElementById('sendpopup')
    element.style.display = "block"
    element.classList.remove('superanimation'); // reset animation
    void element.offsetWidth; // trigger reflow
    element.classList.add('superanimation'); // start animation


    const overlay = document.getElementById('overlay')
    overlay.style.visibility = "visible"
    overlay.style.opacity = "1"

    $('.navbar').css('filter', 'blur(2px) grayscale(1)')
    $('.max960').css('filter', 'blur(2px) grayscale(1)')

    $('body').css('overflow', 'hidden')
  }
  copyAddress(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.snackBar.open("Adresse copié", '', { duration: 3000 })
  }

  closefunc() {
    console.log("qsdfqsd")
    const element = document.getElementById('sendpopup');
    element.classList.remove('superanimation'); // reset animation
    element.style.display = 'none';


    const overlay = document.getElementById('overlay')
    overlay.style.visibility = "hidden"
    overlay.style.opacity = "0"

    this.productservice.paymentSuccess = false
    $('.hidePayment').show(200)
    this.amountToSend = ""
    this.toPublicKey = ""

    $('.navbar').css('filter', 'blur(0px) grayscale(0)')
    $('.max960').css('filter', 'blur(0px) grayscale(0)')
    $('body').css('overflow', 'visible')
  }


  // loginForm = new FormGroup({
  //   email: new FormControl(''),
  //   password: new FormControl('')
  // });

  createAccount = false;
  public user: SocialUser;
  public loggedIn: boolean;

  name: any[];


  ngOnInit() {



    // this.productservice.getcookieconnect()
    if (this.cookieService.get('email') != "") {
      this.productservice.user_login(this.productservice.login)

    }

    this.orderdetails = this.productservice.orderdetails
    this.authService.authState.subscribe((user) => {
      this.user = user;
      if (this.user) {
        // faire attention à cette fonction, à revoir, reload de la page apres avoir etre logué avec google
        if (this.cookieService.get('email') != this.user.email) {
          location.reload()
        }
        // <<fin de faire attention
        document.cookie = "email=" + this.user.email;
        document.cookie = "password=" + this.user.id

        this.productservice.user_registerGoogle({
          email: this.user.email,
          password: this.user.id,
          lastname: this.user.lastName,
          firstname: this.user.firstName
        })
        console.log(this.cookieService.get('email'))


        // this.productservice.changeMessage(this.productservice.accountInformation[0])
      }
    });

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });

    this.registerForm = this.formBuilder.group({
      pseudo: ['', [Validators.required, Validators.maxLength(20)]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });

    // console.log(this.productservice.accountInformation)
    // this.firstname = this.productservice.accountInformation[1].lastname

    this.productservice.infoMessage.subscribe(info => this.infoprofile = info)


  }


  get f() { return this.loginForm.controls; }
  get r() { return this.registerForm.controls; }

  modifyinfo() {
    this.snackBar.open("You can add and modify your personal details while checking your order.", '', { duration: 5000 })
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    if (this.user) {
      this.authService.signOut();
    }
    this.cookieService.delete('email');
    this.cookieService.delete('password');
    this.productservice.changeMessage([])
    this.productservice.accountInformation = []
  }

  signOutForceGlobal() {

  }



  connect() {
    this.submitted = true;
    this.spinner = true
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      setTimeout(() => {
        this.spinner = false
      }, 700);
      return;
    } else {
      this.productservice.user_login(this.loginForm.value)
      setTimeout(() => {
        this.spinner = false
      }, 700);
    }
  }


  register() {
    this.spinner = true
    console.log(this.r)
    this.submittedRegister = true
    if (this.registerForm.invalid) {
      return;
    }
    this.productservice.user_register(this.registerForm.value)
  }

  check() {
    console.log(Object.values(this.productservice.accountInformation).length)

  }

}
