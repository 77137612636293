import { Component, OnInit, Input, ɵConsole, Inject, PLATFORM_ID } from '@angular/core';
import { SelectmyticketsComponent } from '../selectmytickets/selectmytickets.component';
import { ProductsService } from '../services/products.service';
import { CookieService } from 'ngx-cookie-service';
import { isPlatformBrowser, Location } from '@angular/common';
import { RouterLink, Router } from '@angular/router';
import { routerNgProbeToken } from '@angular/router/src/router_module';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.css']
})
export class BasketComponent implements OnInit {
  message: any[]
  cookie: any
  isBrowser: any
  elem
  constructor(@Inject(PLATFORM_ID) private platformId: any,
    public productservice: ProductsService,
    private cookieService: CookieService,
    private router: Router) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      
      if (!localStorage.ticketChoice) {
        localStorage.ticketChoice = JSON.stringify([])
      }

      this.elem = document.documentElement;
      // Verification si il y a des données dans le panier
      this.productservice.checkLocalStorage()
      this.productservice.basketsummarylist.subscribe((message) => {
        this.message = message;
        if (message !== undefined && message.length != 0) {
          this.productservice.orderSummary = message[message.length - 1].basketTotal
          this.productservice.orderSummaryTotalTicket = message[message.length - 1].totalticket
        }
        // console.log(message)
      })

      // console.log(this.message)

      // if(this.cookieService.get('email') != ""){
      //   this.productservice.user_login(this.productservice.login)
      // }
      
    } // FIN is platformbrowser
  }


  openBucket() {
    if (this.productservice.bucketOpened == false) {

      this.productservice.bucketOpened = true
      $('.overlayBucket').show()
      var objDiv = document.getElementById("basketOverlay");
      objDiv.scrollTop = objDiv.scrollHeight;
    } else {
      this.productservice.bucketOpened = false
      $('.overlayBucket').hide()
    }

  }

  clearBasket() {
    this.message = undefined
    this.productservice.basketExchange(this.message)
    this.productservice.orderSummary = 0
    this.productservice.orderSummaryTotalTicket = 0
    this.productservice.totalticket = 0
    this.productservice.positionChoices = []
    this.router.navigateByUrl('/select')

  }

  hideBasket() {
    this.productservice.bucketOpened = false
    $('.overlayBucket').hide()

  }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }
}
