import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-roadmap',
  templateUrl: './roadmap.component.html',
  styleUrls: ['./roadmap.component.css']
})
export class RoadmapComponent implements OnInit {

  constructor(@Inject(PLATFORM_ID) private platformId: any,

  ) { }

  ngOnInit() {

    if (isPlatformBrowser(this.platformId)) {
      $("html, body").animate({ scrollTop: 0 }, "slow");
    }
  }

}
