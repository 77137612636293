import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductsService } from '../services/products.service';

@Component({
  selector: 'app-billing-adress',
  templateUrl: './billing-adress.component.html',
  styleUrls: ['./billing-adress.component.css']
})
export class BillingAdressComponent implements OnInit {
  billingAdress: FormGroup
  submitted = false;

  constructor(private productservice: ProductsService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.billingAdress = this.formBuilder.group({
      name: ['', [Validators.required]],
      surname: ['', [Validators.required]],
      adressLine1: ['', [Validators.required]],
      adressLine2: [''],
      town: ['', [Validators.required]],
      postCode: ['', [Validators.required]]
    });

  

  }

  get f() { return this.billingAdress.controls; }

  connect(){
    this.submitted = true;
    console.log(this.billingAdress.controls.adressLine1.value.length)
  }

}
