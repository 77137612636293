import { BrowserModule } from '@angular/platform-browser';
import { NgModule, enableProdMode, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule, ROUTES, Routes } from '@angular/router';
import { ProductsComponent } from './products/products.component';
import { InformationsComponent } from './informations/informations.component';
import { SelectmyticketsComponent } from './selectmytickets/selectmytickets.component';
import { WinnersComponent } from './winners/winners.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ProductsService } from './services/products.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PinchZoomModule } from "ngx-pinch-zoom";
import { NgxStripeModule } from 'ngx-stripe';
import { BasketComponent } from './basket/basket.component';
import { CookieService } from 'ngx-cookie-service';
import { CompetitionComponent } from './competition/competition.component';
import { AccountComponent } from './account/account.component';
import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { HttpHeaders } from '@angular/common/http';
import { CheckoutComponent } from './checkout/checkout.component';
import { BillingAdressComponent } from './billing-adress/billing-adress.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule, MatSnackBarModule } from '@angular/material';
import { RegisterComponent } from './register/register.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { HomeComponent } from './home/home.component';
import { PlacedOrderComponent } from './placed-order/placed-order.component';
import { RulesComponent } from './rules/rules.component';
import { TermsofplayComponent } from './termsofplay/termsofplay.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ContactComponent } from './contact/contact.component';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { RoadmapComponent } from './roadmap/roadmap.component';
import { OffresComponent } from './offres/offres.component';
import { LottieAnimationViewModule } from 'ng-lottie';
import { ExchangeComponent } from './exchange/exchange.component';
import { EquipeComponent } from './equipe/equipe.component';
import { FaqComponent } from './faq/faq.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TokenomicsComponent } from './tokenomics/tokenomics.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
registerLocaleData(localeFr);

const headers = new HttpHeaders()
  .set("Access-Control-Allow-Origin", "*");


let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("129850637641-slpgh21hpire98iv512rhn060qd88cau.apps.googleusercontent.com")
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("370583309811429")
  }
]);

export function provideConfig() {
  return config;
}

// Note we need a separate function as it's required
// by the AOT compiler.

@NgModule({
  declarations: [
    AppComponent,
    ProductsComponent,
    InformationsComponent,
    SelectmyticketsComponent,
    WinnersComponent,
    BasketComponent,
    CompetitionComponent,
    AccountComponent,
    CheckoutComponent,
    BillingAdressComponent,
    RegisterComponent,
    HomeComponent,
    PlacedOrderComponent,
    RulesComponent,
    TermsofplayComponent,
    PrivacyComponent,
    ContactComponent,
    RoadmapComponent,
    OffresComponent,
    ExchangeComponent,
    EquipeComponent,
    FaqComponent,
    TokenomicsComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    PinchZoomModule,
    SocialLoginModule,
    NgxStripeModule.forRoot('pk_live_GqC2ypFnL1B0CNZKgCQghPOV'),
    BrowserAnimationsModule,
    MatTooltipModule,
    MatSnackBarModule,
    DeviceDetectorModule.forRoot(),
    RouterModule,
    SlickCarouselModule,
    LottieAnimationViewModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig,
    },
    ProductsService,
    CookieService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + Date.now());
}