import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { formatCurrency, isPlatformBrowser, Location, DOCUMENT } from '@angular/common';
import { ProductsService } from '../services/products.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {



  plan50000: any = 50000
  plan35000: any = 35000
  surfaceterrain: any = 11000
  prixmetrecarre: any = Math.round(240000 / 11000)
  quantity: any = 1
  products: any[]
  translatego: any
  conditiontr: any
  url:any
  constructor(@Inject(PLATFORM_ID) private platformId: any,
    public productservice: ProductsService,
    private translate: TranslateService,
    private router: Router,
    private location: Location,
    @Inject(DOCUMENT) private document: any
  ) {
    // this.translate.setDefaultLang('en');
    // this.translate.use('en');

    console.log(this.document.location.href)
    // this.url = this.location
    
    // console.log(this.url._platformStrategy._platformLocation.location.href)
    
    this.conditiontr = this.document.location.href.indexOf("en.gigatree") > -1
    if (this.conditiontr == true) {
      this.translate.setDefaultLang('en');
      this.translate.use('en');
      console.log("en")
    } else {
      this.translate.setDefaultLang('fr');
      this.translate.use('fr');
      console.log("fr")
    }




  }

  ngOnInit() {

    if (isPlatformBrowser(this.platformId)) {
      this.conditiontr = window.location.href.indexOf("en.gigatree") > -1
      $("html, body").animate({ scrollTop: 0 }, "slow");


      // if (window.location.href.indexOf("en.gigatree") > -1) {
      //   this.translatego = this.productservice.translateen
      // } else {
      //   this.translatego = this.productservice.translatefr
      // }
    }


    console.log(this.prixmetrecarre)
    this.products = this.productservice.treeproducts

  }

  quantitymoins(product) {

    if (this.productservice.treeproducts[product.index].defaultquantity > 1) {
      this.productservice.treeproducts[product.index].defaultquantity = this.productservice.treeproducts[product.index].defaultquantity - 1
    }

    // console.log(formatCurrency(45000, 'fr', 'EUR'))
  }


  quantityplus(product) {

    console.log(product)
    console.log(product.index)

    if (this.productservice.treeproducts[product.index].defaultquantity >= 1) {
      this.productservice.treeproducts[product.index].defaultquantity = this.productservice.treeproducts[product.index].defaultquantity + 1
    }

  }

  test(product) {
    console.log(product)
    this.productservice.treebasket.push(product)
    console.log(this.productservice.treebasket)
  }

}
