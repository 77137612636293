import { Component, Input, PLATFORM_ID, Inject, Injectable } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { ProductsService } from './services/products.service';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { load } from '@angular/core/src/render3';
import { TranslateService } from '@ngx-translate/core';
import { loadLContext } from '@angular/core/src/render3/discovery_utils';
import { Router  } from '@angular/router';


declare global {
  interface Window {
    hj: any;
    _hjSettings: any
  }
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  isChrome: boolean = false
  tt: any
  intervalcounter: any
  speed: any
  conditiontr: any

  constructor(@Inject(PLATFORM_ID) private platformId,
    private meta: Meta, private title: Title,
    public productservice: ProductsService,
    private deviceService: DeviceDetectorService,
    private translate: TranslateService,
    public router : Router,
    @Inject(DOCUMENT) private document: any) {
    this.meta.updateTag({ name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' });
    this.title.setTitle('Gigatree.eu | Invest, buy and sell to plant trees')
  }

  ngOnInit() {

console.log(this.document.location)


    this.conditiontr = this.document.location.href.indexOf("en.gigatree") > -1
    if (this.conditiontr == true) {
      this.translate.setDefaultLang('en');
      this.translate.use('en');
      console.log("en")
    } else {
      this.translate.setDefaultLang('fr');
      this.translate.use('fr');
      console.log("fr")
    }

  }

  fbload() {
    (function (f: any, b, e, v, n, t, s) {
      if (f.fbq) return; n = f.fbq = function () {
        n.callMethod ?
          n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      }; if (!f._fbq) f._fbq = n;
      n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async = !0;
      t.src = v; s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s)
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    (window as any).fbq.disablePushState = true; //not recommended, but can be done
    (window as any).fbq('init', '497437777663023');
    (window as any).fbq('track', 'PageView');
  }


}
