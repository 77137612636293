import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable, Input, Inject, PLATFORM_ID, OnInit } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { User } from '../user.model';
import { Products } from '../products.model';
import { digest } from '@angular/compiler/src/i18n/serializers/xmb';
import { CookieService } from 'ngx-cookie-service';
import { isPlatformBrowser } from "@angular/common";
import { stringify } from '@angular/compiler/src/util';
import { MatSnackBar, MatSnackBarModule } from '@angular/material';
import { RequestOptions } from '@angular/http';



@Injectable()
export class ProductsService {
  constructor(@Inject(PLATFORM_ID) private platformId: any,
    private httpClient: HttpClient,
    private cookieService: CookieService,
    public snackBar: MatSnackBar,
    private router: Router) { }

  public googleuser = {}
  public login = {
    email: this.cookieService.get('email'),
    password: this.cookieService.get('password')
  }
  
  translatefr: any = {
    "home": {
      "title": "La première monnaie numérique qui fait pousser des arbres.",
      "transactionRapide": "Transactions rapides et sécurisées",
      "transactionRapideSubText": "Les transactions sont <b>instantanées</b> et donc plus rapides que le bitcoin ou n’importe quels monnaies numériques de la blockchain. Les frais de transactions sont dérisoires comparé aux frais bancaires de votre boulanger préféré."
    }
  }

  translateen: any = {
    "home": {
      "title": "trees baby",
      "transactionRapide": "Transactions rapides et sécurisées",
      "transactionRapideSubText": "Les transactions sont <b>instantanées</b> et donc plus rapides que le bitcoin ou n’importe quels monnaies numériques de la blockchain. Les frais de transactions sont dérisoires comparé aux frais bancaires de votre boulanger préféré."
    }
  }
  translatego: any = this.translatefr

competitioncountervalue: any = []
orderdetails: any = []
phasesPrices: any[]
paymentdeclined: any
paymentsubmit: boolean = false
accountInformation: any[] = []
positionChoices: any[] = []
res: { }
bucketOpened: boolean = false
bucket: any[]
productlistsubject = new Subject<any[]>();
  private products = []
  private testObservable: Observable < any[] >
  private test = []
// Gigatree transactions
paymentProcessingLive: any = false
paymentSuccess: any = false
// treecoin
treebasket: any = []
treeproducts: any = [
  {
    name: 'plan50000',
    defaultquantity: 1,
    value: 2500,
  },
  {
    name: 'plan35000',
    defaultquantity: 1,
    value: 1500,
  },
  {
    name: 'plan10000',
    defaultquantity: 1,
    value: 450,
  },
  {
    name: 'plan5000',
    defaultquantity: 1,
    value: 150,
  },
  {
    name: 'plan500',
    defaultquantity: 1,
    value: 50,
  }
]

walletPublic: any
walletSecret: any
walletAmount: any
// 

productsUrl = "http://163.172.174.96:5555/"
apiUrl = "https://api.gigatree.eu"
// apiUrl = "http://localhost:5555"

parame = {
  id: 56
}
// Panier
basket: any[] = []
orderSummary: any
orderSummaryTotalTicket: any
// nombre total de tickets sélectionné
totalticket: number = 0
basketTotalTicket: any = 0

  private messageSource = new BehaviorSubject<any[]>(this.basket);
basketsummarylist = this.messageSource.asObservable()

  public infoSource = new BehaviorSubject<any>(this.accountInformation)
infoMessage = this.infoSource.asObservable()



  public optionRequete = {
  headers: new HttpHeaders({
    'Referrer-Policy': 'unsafe-url'
  })
};

changeMessage(info: any[] = []) {
  this.infoSource.next(info)
}



pushPositionTicketChoice(product) {

  let i;
  for (i = 0; i < product.value; i++) {
    this.positionChoices.push(
      {
        'product': product.product.id,
        'productname': product.product.name,
        'positionx': null,
        'positiony': null
      }
    )
  }


  if (isPlatformBrowser(this.platformId)) {
    localStorage.ticketChoice = JSON.stringify(this.positionChoices)
    this.positionChoices = JSON.parse(localStorage.ticketChoice)
  }
}

checkLocalStorage() {
  if (isPlatformBrowser(this.platformId)) {
    if (localStorage.basket) {
      this.basketExchange(JSON.parse(localStorage.basket))
    }
  }
}

basketExchange(message: any[]) {
  // console.log(message)
  // for (i in message) {
  //   console.log(message[i].price);
  // }

  if (message == undefined) {
    this.messageSource.next(message)
    if (isPlatformBrowser(this.platformId)) {
      localStorage.clear()
    }
  } else {
    var total = 0;
    var totalticket = 0;

    for (var i = 0; i < message.length; i++) {
      total += parseFloat(message[i].product.price) * parseFloat(message[i].value);
      totalticket += parseFloat(message[i].value);
      message[i].basketTotal = total.toFixed(2)
      message[i].totalticket = totalticket
      this.totalticket = totalticket
    }

    // console.log(message)

    this.messageSource.next(message)


    if (isPlatformBrowser(this.platformId)) {
      localStorage.basket = JSON.stringify(message)
      console.log(JSON.parse(localStorage.basket))
      console.log(this.totalticket)
    }
  }



  // this.cookieService.delete('dfg');
  // this.cookieService.set( 'dfg', JSON.stringify(this.bucket));
  // // console.log(JSON.stringify(this.bucket))
  // console.log(JSON.parse(this.cookieService.get('dfg')))
  // console.log(this.bucket)



  // message.forEach(element => {
  //   this.basketTotal = this.basketTotal + element.product.price
  //   console.log(this.basketTotal)
  // });
  // console.log(message[message.length-1].totalticket)

}




emitproductlist() {
  this.productlistsubject.next(this.products.slice())
}

getProducts() {
  return this.httpClient.get<Products[]>(this.productsUrl)
}


ngOnInit() {

}

payment(data, billinginfo) {
  console.log(billinginfo)
  var reqData = {
    data: JSON.stringify(data),
    billinginfo: JSON.stringify(billinginfo),
    login: JSON.stringify(this.login),
    ticketchoice: JSON.stringify(localStorage.ticketChoice),
    pricebeforediscount: JSON.stringify(this.orderSummary)
  }



  const httpOptions = {
    headers: { 'Access-Control-Allow-Origin': '*' },
    params: {
      data: JSON.stringify(data),
      billinginfo: JSON.stringify(billinginfo),
      login: JSON.stringify(this.login),
      ticketchoice: localStorage.ticketChoice,
      pricebeforediscount: JSON.stringify(this.orderSummary)
    }
  };




  this.httpClient.post(this.apiUrl + "/payment", reqData).subscribe((res) => {
    console.log(res)
    if (res == "réussi") {
      this.router.navigateByUrl('/final');
      this.paymentsubmit = false
      this.paymentdeclined = false

      this.basketExchange(undefined)
      this.orderSummary = 0
      this.orderSummaryTotalTicket = 0
      this.totalticket = 0
      this.positionChoices = []
    }

    if (res != "réussi") {
      this.paymentsubmit = false
      this.paymentdeclined = true
    }
  });
}

getcookieconnect() {
  this.login = {
    email: this.cookieService.get('email'),
    password: this.cookieService.get('password')
  }
}

user_login(data: any) {

  this.getcookieconnect()

  this.httpClient.get(this.apiUrl + "/login", {
    params: data
  }).subscribe((res) => {
    this.accountInformation = Object.values(res)

    if (this.accountInformation[0] == true) {
      document.cookie = "email=" + data.email
      document.cookie = "password=" + data.password
      this.changeMessage(this.accountInformation[1])
      this.getcookieconnect()
      this.order_details(this.login)
      this.userWallet()
    } else {
      this.cookieService.delete('email')
      this.cookieService.delete('password')
      this.snackBar.open("Mauvais mot de passe ou compte non existant.", 'Fermer')
    }
  });


}

userWallet() {
  this.httpClient.get(this.apiUrl + "/userWallet", { params: this.login }).subscribe((res) => {
    // this.accountInformation = Object.values(res)
    console.log(Object.values(res))

    this.walletPublic = Object.values(res)[1]
    this.walletSecret = Object.values(res)[2]
    this.walletAmount = Object.values(res)[3]
  });
}


phasesPricesGet() {
  this.httpClient.get(this.apiUrl + "/phasesPrices").subscribe((res) => {
    // this.accountInformation = Object.values(res)

    this.phasesPrices = Object.values(res)

  });
}
sendTransaction(keyPublic, keySecret, amount, toPublicKey) {
  var transactionInfo = {
    keyPublic: keyPublic,
    keySecret: keySecret,
    toPublicKey: toPublicKey,
    amount: amount
  }

  $('.hidePayment').hide(200)

  this.paymentProcessingLive = true

  console.log(transactionInfo)

  this.httpClient.post(this.apiUrl + "/send", { params: transactionInfo }).subscribe((res) => {
    // this.accountInformation = Object.values(res)
    // console.log(Object.values(res))
    console.log(res)

    if (res == 'ok') {
      this.paymentProcessingLive = false
      this.paymentSuccess = true
      this.userWallet()
    } else {
      $('.hidePayment').show(200)
      this.paymentProcessingLive = false
      this.snackBar.open("Erreur pendant le paiement, vérifier les informations de transactions", '', { duration: 2000 })
    }


  });
}

makeid(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}


order_details(data: any) {
  this.httpClient.post(this.apiUrl + "/order_details", { params: data }).subscribe((res) => {

    this.orderdetails = res
    console.log(res)

  });
}


user_register(data: any) {
  this.httpClient.get(this.apiUrl + "/register", { params: data }).subscribe((res) => {

    if (res == true) {
      document.cookie = "email=" + data.email
      document.cookie = "password=" + data.password

      // this.router.navigateByUrl('/account');
      document.location.reload();
      // window.location.href = "/select";
      this.snackBar.open("Your account has been created", '', { duration: 3000 })
    } else if (res == "email existe") {
      this.snackBar.open("Cet email est déjà utilisé", 'Close')
    } else if (res == "pseudo existe") {
      this.snackBar.open("Ce pseudo est déjà utilisé", 'Close')
    }
  });
}

user_registerGoogle(data: any) {

  this.httpClient.get(this.apiUrl + "/register", { params: data }).subscribe((res) => {
    if (res == true) {
      document.cookie = "email=" + data.email;
      document.cookie = "password=" + data.password
      location.reload();

    } else if (res == "existe") {
      console.log("compte google deja existant")
      // location.reload();
      // this.changeMessage(data)
    }
  });
}

competitioncounter() {

  this.httpClient.get(this.apiUrl + "/competitioncounter").subscribe((res) => {
    if (res) {
      this.competitioncountervalue = res[0].competition
    }
  });
}



}
