import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { MatSnackBar } from '@angular/material';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ProductsService } from '../services/products.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-offres',
  templateUrl: './offres.component.html',
  styleUrls: ['./offres.component.css']
})
export class OffresComponent implements OnInit {
  plan50000: any = 50000
  plan35000: any = 35000
  surfaceterrain: any = 11000
  prixmetrecarre: any = Math.round(50000 / 11000)
  quantity: any = 1
  products: any[]
  slickCounter: any = 0
  constructor(@Inject(PLATFORM_ID) private platformId: any,
    public productservice: ProductsService,
    private translate: TranslateService,
    public matsnackbar: MatSnackBar,
    @Inject(DOCUMENT) private document: any
  ) { }

  conditiontr: any

  slides = [
    { img: "http://placehold.it/350x150/000000" },
    { img: "http://placehold.it/350x150/111111" },
    { img: "http://placehold.it/350x150/333333" },
    { img: "http://placehold.it/350x150/666666" },
    { img: "http://placehold.it/350x150/111111" },
    { img: "http://placehold.it/350x150/333333" },
    { img: "http://placehold.it/350x150/666666" },
    { img: "http://placehold.it/350x150/111111" },
    { img: "http://placehold.it/350x150/333333" },
    { img: "http://placehold.it/350x150/666666" }
  ];
  slideConfig = {
    "slidesToShow": 8, "slidesToScroll": 8, "autoplaySpeed": 300, responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: true
        }
      }, {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true
        }
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 570,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };

  petit(value){
    return Math.floor(value)
  }

  addSlide() {
    this.slides.push({ img: "http://placehold.it/350x150/777777" })
  }

  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  afterChange(e) {
    console.log('afterChange');
  }

  beforeChange(e) {
    console.log('beforeChange');
  }

  super(){
    console.log('test')
    this.matsnackbar.open(this.translate.instant('snack.buyBtn'), '', { duration: 3000 })
  }

  ajouterSlick(){
    if(this.slickCounter >= 140){
      this.slickCounter = 140
      return 140
    } else {
      this.slickCounter = this.slickCounter + 10
      return this.slickCounter
    }
  }

  revenirSlick(){
    if(this.slickCounter <= 1){
      this.slickCounter = 1
      return 1
    } else {
      this.slickCounter = this.slickCounter - 10
      return this.slickCounter
    }
  }


  
  ngOnInit() {
    // récupère les phases
    this.productservice.phasesPricesGet()


    this.conditiontr = this.document.location.href.indexOf("en.gigatree") > -1
    if (this.conditiontr == true) {
      this.translate.setDefaultLang('en');
      this.translate.use('en');
      console.log("en")
    } else {
      this.translate.setDefaultLang('fr');
      this.translate.use('fr');
      console.log("fr")
    }

    if (isPlatformBrowser(this.platformId)) {
      $("html, body").animate({ scrollTop: 0 }, "slow");

      console.log(this.prixmetrecarre)
      this.products = this.productservice.treeproducts


      // Set the date we're counting down to
      var countDownDate = new Date("Jan 1, 2021 00:00:00").getTime();

      // Update the count down every 1 second
      var x = setInterval(function () {

        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Output the result in an element with id="demo"
        document.getElementById("demo").innerHTML = days + " Jours " + hours + " Heures "
          + minutes + " Minutes ";

        // If the count down is over, write some text 
        if (distance < 0) {
          clearInterval(x);
          document.getElementById("demo").innerHTML = "EXPIRED";
        }
      }, 1000);
    }
  }
  ngOnDestroy(){
    this.productservice.phasesPrices = []
  }

  quantitymoins(product) {

    if (this.productservice.treeproducts[product.index].defaultquantity > 1) {
      this.productservice.treeproducts[product.index].defaultquantity = this.productservice.treeproducts[product.index].defaultquantity - 1
    }

    // console.log(formatCurrency(45000, 'fr', 'EUR'))
  }


  quantityplus(product) {

    console.log(product)
    console.log(product.index)

    if (this.productservice.treeproducts[product.index].defaultquantity >= 1) {
      this.productservice.treeproducts[product.index].defaultquantity = this.productservice.treeproducts[product.index].defaultquantity + 1
    }

  }

  test(product) {
    console.log(product)
    this.productservice.treebasket.push(product)
    console.log(this.productservice.treebasket)
  }

}
