import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';

@Component({
  selector: 'app-termsofplay',
  templateUrl: './termsofplay.component.html',
  styleUrls: ['./termsofplay.component.css']
})
export class TermsofplayComponent implements OnInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
  ) { }

  ngOnInit() {
    $("html, body").animate({ scrollTop: 0 }, "slow");

  }

}
