import { Component, OnInit, HostListener, PLATFORM_ID, Inject, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ProductsService } from '../services/products.service';
import { Positions } from "../positions.model";
import { TemplateBindingParseResult } from '@angular/compiler';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { StripeService, Elements, Element as StripeElement, ElementsOptions } from "ngx-stripe";
import { element } from '@angular/core/src/render3';
import { isPlatformBrowser } from '@angular/common';
import * as $ from 'jquery'
import interact from 'interactjs';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';




let selectedcursors = []
let Ymobile = 0
let Xmobile = 0
let xfinal = 0
let yfinal = 0
let xpc = 0
let ypc = 0
let zoomcursorpos = 10
let xnew
let ynew



@Component({
  selector: 'app-competition',
  templateUrl: './competition.component.html',
  styleUrls: ['./competition.component.css']
})


export class CompetitionComponent implements OnInit {


  toPlay: any
  basketMain: any[] = []
  public isBrowser = isPlatformBrowser(this.platformId);
  products = []
  tabs = []
  credits: any
  infotxtnext = ""
  notVisible: boolean = false
  intermobile

  constructor(@Inject(PLATFORM_ID) public platformId: any,
    public productService: ProductsService,
    public fb: FormBuilder,
    public _el: ElementRef,
    public pinch: PinchZoomModule,
    public snackBar: MatSnackBar,
    public router: Router) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  addticketvisible = false
  playbtnstartwait = false


  ngOnInit() {

    let deviceSizeDezoom = 0
    let zoomvalue
    let img
    let imgpc
    let zoomvaluepc
    let addticketvisible = false

    //or however you get a handle to the IMG

    let hold_time = 25
    // initialisation interval for each button
    let rr
    let ll
    let uu
    let dd

    // hold_trigger.mousedown(function () {
    //   timeout_id = setInterval(menu_toggle, hold_time);
    // }).bind('mouseup mouseleave', function () {
    //   clearInterval(timeout_id);
    //   hold_menu.toggle();
    // });

    if (isPlatformBrowser(this.platformId)) {
      $('.btnCursorRightSide').on({
        'touchstart': function (e) {
          // e.preventDefault();
          console.log("fqsd")
          timerRight()
          rr = setInterval(timerRight, hold_time)
        }
      });

      $('.btnCursorRightSide').on({
        'touch': function (e) {
          // e.preventDefault();
          timerRight()
        }
      });

      $('.btnCursorRightSide').on({
        'touchend': function () {
          clearInterval(rr)
        }
      });

      $('.btnCursorLeftSide').on({
        'touchstart': function (e) {
          // e.preventDefault();
          console.log("fqsd")
          timerLeft()
          ll = setInterval(timerLeft, hold_time)
        }
      });

      $('.btnCursorLeftSide').on({
        'touch': function (e) {
          // e.preventDefault();
          timerLeft()
        }
      });

      $('.btnCursorLeftSide').on({
        'touchend': function () {
          clearInterval(ll)
        }
      });

      $('.btnCursorUpSide').on({
        'touchstart': function (e) {
          // e.preventDefault();
          console.log("fqsd")
          timerUp()
          uu = setInterval(timerUp, hold_time)
        }
      });

      $('.btnCursorUpSide').on({
        'touch': function (e) {
          // e.preventDefault();
          timerUp()
        }
      });

      $('.btnCursorUpSide').on({
        'touchend': function () {
          clearInterval(uu)
        }
      });

      $('.btnCursorDownSide').on({
        'touchstart': function (e) {
          // e.preventDefault();
          console.log("fqsd")
          timerDown()
          dd = setInterval(timerDown, hold_time)
        }
      });

      $('.btnCursorDownSide').on({
        'touch': function (e) {
          // e.preventDefault();
          timerDown()
        }
      });

      $('.btnCursorDownSide').on({
        'touchend': function () {
          clearInterval(dd)
        }
      });
    }

    if (isPlatformBrowser(this.platformId)) {

      var photobig = new Image();
      photobig.onload = function () {

        // affichage jeu mobile
        $(document).ready(function () {
          $(window).scrollTop(0);
        });
        img = document.getElementById('mobilesize');

        Ymobile = $(".liney-mobile").offset().top - $(".imgdrag").offset().top;
        Xmobile = $(".linex-mobile").offset().left - $(".imgdrag").offset().left;
        deviceSizeDezoom = img.naturalWidth / $(".gamebox-mobile").width()
        // console.log(deviceSizeDezoom)

        imgpc = document.getElementById('imglargepczoom');
        zoomvaluepc = imgpc.naturalWidth / $('.imgref').width()
        console.log(zoomvaluepc)


        console.log($(".cursorMobileSelector").length)
        // console.log(this.addticketvisible)
      }

      photobig.src = "/assets/img/photobig.png";

    }



    if (isPlatformBrowser(this.platformId)) {

      setTimeout(() => {
                // premier timeout>>>> a deplacer plus haut si bug
                JSON.parse(localStorage.ticketChoice).forEach(el => {
                  let ticketcursorclass = 'position: absolute; left:' + ((el.positionx / deviceSizeDezoom) - 7) + 'px; top:' + ((el.positiony / deviceSizeDezoom) - 7) + 'px; z-index: 30;'
                  if (el.positionx != null) {
                    $('pinch-zoom div').append('<img src="/assets/img/line.svg" class="animated fadeIn mobilecursorpinchgame" style="' + ticketcursorclass + '" />')
                  }
                });
                if (JSON.parse(localStorage.ticketChoice).some(item => item.positionx == null) == false) {
                  this.addticketvisible = true
                }
        
                //<<<<<fin premier timeout
      }, 2000);

      this.intermobile = setInterval(function () {


        Ymobile = Math.round($(".liney-mobile").offset().top) - $(".imgdrag").offset().top + 1;
        Xmobile = Math.round($(".linex-mobile").offset().left) - $(".imgdrag").offset().left + 1;
        // console.log(Xmobile)

        // if(yfinal > img.naturalHeight){
        //   yfinal = img.naturalHeight
        // }
        yfinal = Math.round(Ymobile * deviceSizeDezoom)
        if (img) {
          if (yfinal > img.naturalHeight) {
            yfinal = img.naturalHeight
          }
        }
        xfinal = Math.round(Xmobile * deviceSizeDezoom)

        $('#cord2').text('X:' + xfinal + ' Y:' + yfinal);

        if ($('.pz-dragging').css("transform") != undefined) {
          zoomvalue = $('.pz-dragging').css("transform").split(",")[3]
          $('#zoomvalue2').text(zoomvalue);
          zoomcursorpos = zoomcursorpos / zoomvalue

          Xmobile = Xmobile / zoomvalue
          Ymobile = Ymobile / zoomvalue
          // LIMITATION BORDURE - Problème: il faut arrondir les chiffres, sinon la valeur du point du joueur n'est pas exact et forcer la valeur de 1 au lieu du 0 ( car 1pixel n'est pas égal à 0)
          if (Math.round(Ymobile * deviceSizeDezoom) < 1) {
            yfinal = 1
          } else {
            yfinal = Math.round(Ymobile * deviceSizeDezoom)
          }

          if (Math.round(Xmobile * deviceSizeDezoom) > img.naturalWidth) {
            xfinal = img.naturalWidth
          } else {
            xfinal = Math.round(Xmobile * deviceSizeDezoom)
          }
          // ligne du bas
          if (yfinal > img.naturalHeight) {
            yfinal = img.naturalHeight
          }

          if (xfinal < 1) {
            xfinal = 1
          }

          $('#xmobile').text('devicedezoom: ' + deviceSizeDezoom);


          $('#cord2').text('X: ' + xfinal + ', Y: ' + yfinal);
        } else {
          zoomvalue = 1
          $('#zoomvalue2').text(zoomvalue);
        }

      }, 1000);
    }


    // $('.gamebox-mobile').on("touchend", function (event) {
    //   // setTimeout(() => {

    //   //   Ymobile = $(".liney-mobile").offset().top - $(".imgdrag").offset().top;
    //   //   Xmobile = $(".linex-mobile").offset().left - $(".imgdrag").offset().left;
    //   //   console.log(Xmobile)
    //   //   $('#cord2').text('X: ' + ~~Xmobile * deviceSizeDezoom + ', Y: ' + ~~Ymobile );

    //   //   console.log($('.pz-dragging').css("transform"))
    //   //   if($('.pz-dragging').css("transform") != undefined){
    //   //       zoomvalue = $('.pz-dragging').css("transform").split(",")[3]
    //   //       $('#zoomvalue2').text(zoomvalue);
    //   //       xfinal = Xmobile / zoomvalue
    //   //       yfinal = Ymobile / zoomvalue

    //   //       $('#cord2').text('X: ' + ~~xfinal * deviceSizeDezoom+ ', Y: ' + ~~yfinal);
    //   //   } else {
    //   //     zoomvalue = 1
    //   //     $('#zoomvalue2').text(zoomvalue);
    //   //   }
    //   // }, 350);
    // });


    //GAMETEST 


    var timerID;
    var counter = 0;

    var pressHoldEvent = new CustomEvent("pressHold");

    // Increase or decreae value to adjust how long
    // one should keep pressing down before the pressHold
    // event fires
    var pressHoldDuration = 1000;


    // BOUTON JEU
    // setTimeout(() => {
    //   if (isPlatformBrowser(this.platformId)) {

    //     var item1 = document.querySelector(".btnCursorUp");
    //     var item2 = document.querySelector(".btnCursorRightSide");
    //     var item3 = document.querySelector(".btnCursorDown");
    //     var item4 = document.querySelector(".btnCursorLeftSide");

    //     if (this.addticketvisible == false) {


    //       item1.addEventListener("touchstart", pressingDownUp, false);
    //       item1.addEventListener("touchend", notPressingDownUp, false);

    //       item2.addEventListener("touchstart", pressingDownRight, false);
    //       item2.addEventListener("touchend", notPressingDownRight, false);

    //       item3.addEventListener("touchstart", pressingDownDown, false);
    //       item3.addEventListener("touchend", notPressingDownDown, false);

    //       item4.addEventListener("touchstart", pressingDownLeft, false);
    //       item4.addEventListener("touchend", notPressingDownLeft, false);
    //     }
    //   }

    // }, 1000);






    // RIGHT
    function pressingDownRight(e) {
      // Start the timer
      requestAnimationFrame(timerRight);
      e.preventDefault();
      console.log("Pressing!");
    }

    function notPressingDownRight(e) {
      // Stop the timer
      cancelAnimationFrame(timerID);
      counter = 0;
      console.log("Not pressing!");
    }

    function timerRight() {


      // Ymobile = $(".liney-mobile").offset().top - $(".imgdrag").offset().top;
      // Xmobile = $(".linex-mobile").offset().left + 1
      // Xmobile = Xmobile + 1
      console.log($(".linex-mobile").offset().left + " " + $('.gamebox-mobile').width() + " " + Xmobile + " " + deviceSizeDezoom)
      if (Math.round($(".linex-mobile").offset().left) >= $('.gamebox-mobile').width()) {
        return;
      } else if (Math.round($(".linex-mobile").offset().left) < $('.gamebox-mobile').width() - 1) {
        Xmobile = Xmobile + 1
        // var oj = 
        $('.linex-mobile').css("left", $(".linex-mobile").offset().left + 1)

        console.log(~~Xmobile)
        // console.log("Timer tick!");


      }



    }

    // UP
    function pressingDownUp(e) {
      // Start the timer
      requestAnimationFrame(timerUp);
      e.preventDefault();
      console.log("Pressing!");
    }

    function notPressingDownUp(e) {
      // Stop the timer
      cancelAnimationFrame(timerID);
      counter = 0;
      console.log("Not pressing!");
    }

    function timerUp() {


      // Ymobile = $(".liney-mobile").offset().top - $(".imgdrag").offset().top;
      // Xmobile = $(".linex-mobile").offset().left + 1
      // Xmobile = Xmobile + 1
      // console.log($(".linex-mobile").offset().left + " " + $('.gamebox-mobile').width() + " " + Xmobile + " " + deviceSizeDezoom)
      if (Math.round($(".liney-mobile").offset().top - $(".gamebox-mobile").offset().top) <= 0) {
        return;
      } else if (Math.round($(".liney-mobile").offset().top - $(".gamebox-mobile").offset().top) >= 1) {
        Ymobile = Ymobile - 1
        // var oj = 
        // console.log($(".liney-mobile").offset().top)
        $('.liney-mobile').css("top", (Math.round($(".liney-mobile").offset().top) - $(".gamebox-mobile").offset().top) - 1)

        console.log(~~Ymobile + " dfq")
        // console.log("Timer tick!");

      }



    }

    // DOWN
    function pressingDownDown(e) {
      // Start the timer
      requestAnimationFrame(timerDown);
      e.preventDefault();
      console.log("Pressing!");
    }

    function notPressingDownDown(e) {
      // Stop the timer
      cancelAnimationFrame(timerID);
      counter = 0;
      console.log("Not pressing!");
    }

    function timerDown() {
      console.log(Math.round($(".liney-mobile").offset().top - $(".gamebox-mobile").offset().top))
      // console.log($(".linex-mobile").offset().left + " " + $('.gamebox-mobile').width() + " " + Xmobile + " " + deviceSizeDezoom)
      if (Math.round($(".liney-mobile").offset().top - $(".gamebox-mobile").offset().top) > $(".gamebox-mobile").height()) {
        return;
      } else if (Math.round($(".liney-mobile").offset().top - $(".gamebox-mobile").offset().top) < $(".gamebox-mobile").height() - 1) {
        Ymobile = Ymobile + 1
        $('.liney-mobile').css("top", (Math.round($(".liney-mobile").offset().top) - $(".gamebox-mobile").offset().top) + 1)

      }


    }

    // LEFT
    function pressingDownLeft(e) {
      // Start the timer
      requestAnimationFrame(timerLeft);
      e.preventDefault();
      console.log("Pressing!");
    }

    function notPressingDownLeft(e) {
      // Stop the timer
      cancelAnimationFrame(timerID);
      counter = 0;
      console.log("Not pressing!");
    }

    function timerLeft() {


      // Ymobile = $(".liney-mobile").offset().top - $(".imgdrag").offset().top;
      // Xmobile = $(".linex-mobile").offset().left + 1
      // Xmobile = Xmobile + 1
      console.log($(".linex-mobile").offset().left + " " + $('.gamebox-mobile').width() + " " + Xmobile + " " + deviceSizeDezoom)
      if (Math.round($(".linex-mobile").offset().left) <= 0) {
        return;
      } else if (Math.round($(".linex-mobile").offset().left) > 0) {
        Xmobile = Xmobile - 1
        // var oj = 
        $('.linex-mobile').css("left", $(".linex-mobile").offset().left - 1)

        console.log(~~Xmobile)
        // console.log("Timer tick!");


      }



    }

    //GAMETEST FIN

    // function dragMoveListener (event) {
    //   var target = event.target,
    //       // keep the dragged position in the data-x/data-y attributes
    //       x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx,
    //       y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

    //   // translate the element
    //   target.style.webkitTransform =
    //   target.style.transform =
    //     'translate(' + x + 'px, ' + y + 'px)';

    //   // update the posiion attributes
    //   target.setAttribute('data-x', x);
    //   target.setAttribute('data-y', y);

    //   var top = 0.50*angleScale.scale
    //   var topdifference = top - 0.50

    //   interact(gestureArea).draggable({ 
    //     modifiers: [
    //       interact.modifiers.restrict({
    //         restriction: 'parent',
    //         elementRect: { top: 0.50/angleScale.scale, left: 0.50*angleScale.scale, bottom: 0.50-topdifference, right: 0.50-topdifference}
    //       })
    //     ]
    //   })
    // }

    // var currentScale
    // var angleScale = {
    //   angle: 0,
    //   scale: 1,
    // }
    // var gestureArea = document.getElementById('gesture-area')
    // var scaleElement = document.getElementById('scale-element')
    // var resetTimeout


    // interact(gestureArea)

    //   .draggable({ 
    //     onmove: dragMoveListener,
    //     modifiers: [
    //       interact.modifiers.restrict({
    //         restriction: 'parent',
    //         elementRect: { top: 0.50, left: 0.50, bottom: 0.50, right: 0.50 },
    //         endonly: true
    //       })
    //     ]

    //   })




    //   function reset () {
    //     // scaleElement.style.webkitTransform =
    //     //   scaleElement.style.transform =
    //     //   'scale(1)'

    //     // angleScale.angle = 0
    //     // angleScale.scale = 1
    //   }

    if (isPlatformBrowser(this.platformId)) {
      if (!localStorage.ticketChoosenValue) {
        localStorage.ticketChoosenValue = 0
      }




      // Verifier si on a deja des choix
      if (localStorage.ticketChoice) {
        this.productService.positionChoices = JSON.parse(localStorage.ticketChoice)
      }



      // on initialise les credits, on va chercher dans les choix possibles
      this.credits = this.ticketAvailableToPlay()
      this.toPlay = this.nextTicketToPlay()
      console.log(localStorage.ticketChoice)


      this.infotxtnext = "Placez vos points"
      this.tabs = selectedcursors
      this.productService.getProducts().subscribe(data => this.products = data);


      setTimeout(() => {
        this.progress()
      }, 500);

      setTimeout(() => {
        this.progresspc()
      }, 500);

      // $('.selectorfocuslive').hide()
      $('.imgref').hide()
      setTimeout(() => {
        this.playbtnstartwait = true
        if (isPlatformBrowser(this.platformId)) {
          $('.playBtn').show()
          this.notVisible = false
          $('.imgref').show()
          $('.loadgame').hide()
          JSON.parse(localStorage.ticketChoice).forEach(el => {
            let ticketcursorclass = 'position: absolute; left:' + ((el.positionx / zoomvaluepc) - 9) + 'px; top:' + ((el.positiony / zoomvaluepc) - 9) + 'px; z-index: 30;'
            if (el.positionx != null) {

              $('.gamebox').append('<img src="/assets/img/line.svg" class="pccursorpinchgame" style="' + ticketcursorclass + '" />')
            }
          });
        }

      }, 3000);






      $('.imgref').click(function (e) {

        let offset = $(this).offset();
        let X = (e.pageX - offset.left);
        let Y = (e.pageY - offset.top);
        ypc = ~~Y + 1
        xpc = ~~X + 1

        if (JSON.parse(localStorage.ticketChoice).some(item => item.positionx === xpc) == false) {
          if (localStorage.ticketChoosenValue != JSON.parse(localStorage.ticketChoice).length) {

            X = X - 9.5
            Y = Y - 9.5
            console.log(~~X)
            console.log(~~Y)
            let xselectedcursors = ~~X + 9.5
            let yselectedcursors = ~~Y + 9.5

            let ticketcursorclass = 'position: absolute; left:' + ~~X + 'px; top:' + ~~Y + 'px; z-index: 30;'
            let ticketcursorclasswraplens = 'position: absolute; left:' + 38 + 'px; top:' + 38 + 'px; z-index: 51;'
            $('#coord').text('X: ' + X + ', Y: ' + Y);

            $('.gamebox').append('<img src="/assets/img/line.svg" class="pccursorpinchgame" style="' + ticketcursorclass + '" />')
            selectedcursors.push({ "left": xselectedcursors, "top": yselectedcursors })
            console.log(selectedcursors)
          }
        }




      });


      $('.posxycursor').text("Ready");
      $('img').on('dragstart', function (event) { event.preventDefault(); });
      let Xwraplens
      let Ywraplens



      // Version pc
      let wrapimglargeleft
      let wrapimglargetop

      $('.imgref').mousemove(function (e) {
        let offset = $(this).offset();



        xnew = Math.round(($('.target').offset().left - $(".wrapimglarge").offset().left)) + 18
        ynew = Math.round(($('.target').offset().top - $(".wrapimglarge").offset().top)) + 18
        Xwraplens = (e.pageX - offset.left);
        // console.log(Xwraplens)
        Ywraplens = (e.pageY - offset.top);
        // console.log($('.wrapimglarge').css("left"))
        // console.log(xnew)
        $('#coord').text('X: ' + ~~Xwraplens + ', Y: ' + ~~Ywraplens);
        $('.posxycursor').text('X: ' + xnew + ' | Y: ' + ynew);
        // this.testx = ~~Xwraplens + ":" + ~~Ywraplens
        // console.log(this.testx)
        $('.linex').css("left", ~~Xwraplens)
        $('.liney').css("top", ~~Ywraplens)
        $('.wraplens').css("left", Xwraplens - 50)
        $('.wraplens').css("top", Ywraplens - 50)
        $('.target').css("left", ~~Xwraplens - 16)
        $('.target').css("top", ~~Ywraplens - 16)
        $('.wrapimglarge').css("left", - Xwraplens * zoomvaluepc + 50)
        $('.wrapimglarge').css("top", - Ywraplens * zoomvaluepc + 50)
        wrapimglargeleft = parseInt($('.wrapimglarge').css('left'), 10) - 12
        wrapimglargetop = parseInt($('.wrapimglarge').css('top'), 10) - 12

        $('.choum').remove()

        // selectedcursors.forEach(element => {

        //   let posx = element.left*2 + wrapimglargeleft
        //   let posy = element.top*2 + wrapimglargetop
        //   // console.log(posx + ":" + posy)
        //   let cursorposstyle = 'position: absolute; left:' + posx + 'px; top:' + posy + 'px; z-index: 30;'
        //   $('.wraplens').append('<img src="/assets/img/cursorpointerselector.png" class="choum" style="' + cursorposstyle + '" />')
        // });


        JSON.parse(localStorage.ticketChoice).forEach(el => {

          let posx = el.positionx + wrapimglargeleft
          let posy = el.positiony + wrapimglargetop

          if (el.positionx != null) {
            let cursorposstyle = 'position: absolute; left:' + posx + 'px; top:' + posy + 'px; z-index: 30;'
            $('.wraplens').append('<img src="/assets/img/line.svg" class="choum pccursorpinchgame" style="' + cursorposstyle + '" />')
          }

        });

      });

    }


  }

  ngOnDestroy() {
    if (this.intermobile) {
      clearInterval(this.intermobile);
    }

  }


  // @HostListener('touchend') dosomething(){

  //   let zoomvalue

  //   Ymobile = $(".liney-mobile").offset().top - $(".imgdrag").offset().top;
  //   Xmobile = $(".linex-mobile").offset().left - $(".imgdrag").offset().left;
  //   console.log(Xmobile)

  //   $('#zoomvalue').text(zoomvalue);

  //   if($('.pz-dragging').css("transform") != undefined){
  //       zoomvalue = $('.pz-dragging').css("transform").split(",")[3]
  //       xfinal = Xmobile / zoomvalue
  //       yfinal = Ymobile / zoomvalue

  //       $('#coord').text('X: ' + ~~xfinal + ', Y: ' + ~~yfinal);
  //   }

  // }



  // <img src="/assets/img/line.svg" class="cursorMobile" alt="">
  addpointmobile() {
    if (JSON.parse(localStorage.ticketChoice).some(item => item.positionx == xfinal && item.positiony == yfinal) == true) {
      this.snackBar.open("You have already played a ticket with these coordinates.", '', { duration: 2000 })
      return;
    }
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        if (JSON.parse(localStorage.ticketChoice).some(item => item.positionx === xpc) == false) {
          if (localStorage.ticketChoosenValue != JSON.parse(localStorage.ticketChoice).length) {
            if (localStorage.ticketChoosenValue == 0 || localStorage.ticketChoosenValue == undefined) {
              localStorage.ticketChoosenValue++
              this.productService.positionChoices[localStorage.ticketChoosenValue - 1].positionx = xfinal
              this.productService.positionChoices[localStorage.ticketChoosenValue - 1].positiony = yfinal
              // console.log(this.productService.positionChoices)
              localStorage.ticketChoice = JSON.stringify(this.productService.positionChoices)
              this.credits = this.ticketAvailableToPlay()
              this.toPlay = this.nextTicketToPlay()
              let ticketcursorclass = 'position: absolute; left:' + (Xmobile - 7) + 'px; top:' + (Ymobile - 7) + 'px; z-index: 200;'
              $('pinch-zoom div').first().append('<img src="/assets/img/line.svg" class="animated fadeIn mobilecursorpinchgame" style="' + ticketcursorclass + '" />')
              selectedcursors.push({ 'left': xfinal, "top": yfinal })

            } else {
              localStorage.ticketChoosenValue++
              this.productService.positionChoices[localStorage.ticketChoosenValue - 1].positionx = xfinal
              this.productService.positionChoices[localStorage.ticketChoosenValue - 1].positiony = yfinal
              localStorage.ticketChoice = JSON.stringify(this.productService.positionChoices)
              this.credits = this.ticketAvailableToPlay()
              this.toPlay = this.nextTicketToPlay()
              let ticketcursorclass = 'position: absolute; left:' + (Xmobile - 7) + 'px; top:' + (Ymobile - 7) + 'px; z-index: 200;'
              $('pinch-zoom div').first().append('<img src="/assets/img/line.svg" class="animated fadeIn mobilecursorpinchgame" style="' + ticketcursorclass + '" />')
              selectedcursors.push({ 'left': xfinal, "top": yfinal })
            }
          }
        }
        if (JSON.parse(localStorage.ticketChoice).some(item => item.positionx == null) == false) {
          this.addticketvisible = true
        }
      }, 100);

    }




  }

  pcclic() {
    if (this.credits == 0) {
      this.snackBar.open("You have placed all your tickets, add tickets or go to the next step.", '', { duration: 8000 })
    }
    if (JSON.parse(localStorage.ticketChoice).some(item => item.positionx == xnew && item.positiony == ynew) == true) {
      this.snackBar.open("You have already played a ticket with these coordinates.", '', { duration: 2000 })
      return;
    }
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        if (JSON.parse(localStorage.ticketChoice).some(item => item.positionx === xpc) == false) {
          if (localStorage.ticketChoosenValue != JSON.parse(localStorage.ticketChoice).length) {
            if (localStorage.ticketChoosenValue == 0 || localStorage.ticketChoosenValue == undefined) {
              localStorage.ticketChoosenValue++
              this.productService.positionChoices[localStorage.ticketChoosenValue - 1].positionx = xnew
              this.productService.positionChoices[localStorage.ticketChoosenValue - 1].positiony = ynew
              // console.log(this.productService.positionChoices)
              localStorage.ticketChoice = JSON.stringify(this.productService.positionChoices)
              this.credits = this.ticketAvailableToPlay()
              this.toPlay = this.nextTicketToPlay()

            } else {
              localStorage.ticketChoosenValue++
              this.productService.positionChoices[localStorage.ticketChoosenValue - 1].positionx = xnew
              this.productService.positionChoices[localStorage.ticketChoosenValue - 1].positiony = ynew
              localStorage.ticketChoice = JSON.stringify(this.productService.positionChoices)
              this.credits = this.ticketAvailableToPlay()
              this.toPlay = this.nextTicketToPlay()
            }
          }
        }



      }, 100);
    }
  }

  whatis(er) {
    console.log(er)
  }

  progress() {
    let elem
    let width = 1;
    if (isPlatformBrowser(this.platformId)) {
      elem = document.getElementById('myBar')

      setInterval(frame, 14)
    }


    function frame() {

      if (width <= 30) {
        width++
        elem.style.width = width + "%"
      } else if (width <= 70 && width > 30) {
        setTimeout(() => {
          width++
          elem.style.width = width + "%"
        }, 1000);
      } else if (width <= 99 && width > 70) {
        setTimeout(() => {
          width++
          elem.style.width = width + "%"
        }, 1000);
      } else if (width = 99) {
        return
      }


    }

  }

  progresspc() {
    let elem
    let width = 1;
    if (isPlatformBrowser(this.platformId)) {

      elem = document.getElementById('myBarpc')
      setInterval(frame, 14)
    }


    function frame() {


      if (width <= 30) {
        width++
        elem.style.width = width + "%"
      } else if (width <= 70 && width > 30) {
        setTimeout(() => {
          width++
          elem.style.width = width + "%"
        }, 1000);
      } else if (width <= 99 && width > 70) {
        setTimeout(() => {
          width++
          elem.style.width = width + "%"
        }, 1000);
      } else if (width = 99) {
        return
      }
    }


  }

  ticketAvailableToPlay() {
    if (isPlatformBrowser(this.platformId)) {
      let value = JSON.parse(localStorage.ticketChoice).length - localStorage.ticketChoosenValue
      return value;
    }
  }

  nextTicketToPlay() {
    if (localStorage.ticketChoice == "[]" || (JSON.parse(localStorage.ticketChoice).length == parseInt(localStorage.ticketChoosenValue))) {
      return;
    } else {
      if (isPlatformBrowser(this.platformId)) {
        let value = JSON.parse(localStorage.ticketChoice)
        return value[localStorage.ticketChoosenValue].productname;
      }
    }
  }

  qsdfoi() {
    alert('fds')
  }



  mousedown() {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        if ($(".linex-mobile").offset().left >= $('.gamebox-mobile').width()) {
          return;
        } else {
          Xmobile = Xmobile + 1
          // var oj = 
          $('.linex-mobile').css("left", $(".linex-mobile").offset().left + 1)

          // console.log(~~Xmobile)
          // console.log("Timer tick!");


        }
      }, 100);
    }






  }

}