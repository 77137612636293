import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { ProductsService } from '../services/products.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-winners',
  templateUrl: './winners.component.html',
  styleUrls: ['./winners.component.css']
})
export class WinnersComponent implements OnInit {

  constructor(
    public productservice: ProductsService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) { }
ticketsold
  ngOnInit() {

    if (isPlatformBrowser(this.platformId)) {
      this.productservice.competitioncounter()
      setTimeout(() => {
        $('.progress-bar').css("width", (this.productservice.competitioncountervalue / 5899) * 100 + "%")
        this.ticketsold = 5899 - this.productservice.competitioncountervalue
      }, 2500);

    }
  }

}
