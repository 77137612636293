import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from 'angularx-social-login';
import { ProductsService } from '../services/products.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    public authService: AuthService,
    public productservice: ProductsService,
    private formBuilder: FormBuilder,
    public cookieService: CookieService
  ) { }

  ngOnInit() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
  }
  
  get r() { return this.registerForm.controls; }

  register() {
    console.log(this.r)
    if (this.registerForm.invalid) {
      return;
    }
    this.productservice.user_register(this.registerForm.value)
  }
}
