import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { ProductsService } from "../services/products.service";
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { User } from '../user.model';
import { Products } from '../products.model';
import { Positions } from "../positions.model";
import * as $ from 'jquery'
import { isPlatformBrowser } from "@angular/common";
import { MatSnackBar } from '@angular/material';

let bucket: any[] = []


@Component({
  selector: 'app-selectmytickets',
  templateUrl: './selectmytickets.component.html',
  styleUrls: ['./selectmytickets.component.css']
})

export class SelectmyticketsComponent implements OnInit {

  intervalcounter: any
  basketMain: any[] = []
  selection: any
  message: any[]
  products: Products[]
  quantityAddBasket: number = 1
  prepareAddProductBasket: any[]
  test: any[]
  productSubscription: Subscription;
  nbrTicketToSelect: any[]
  overlay: any = {
    description: "",
    id: 1,
    images: "",
    mainimage: "",
    name: "",
    price: 4,
    shortdesc: "",
    type: "",
    value: 18000
  }
  imgtst: any[] = [
    { url: "0.jpg" },
    { url: "1.jpg" },
    { url: "2.jpg" },
    { url: "3.jpg" },
    { url: "4.jpg" },
    { url: "5.jpg" }
  ]
  

  constructor(@Inject(PLATFORM_ID) private platformId: any,
    public productservice: ProductsService,
    private snackbar: MatSnackBar) { }
  elem;
  ticketsold
  ngOnInit() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    this.productservice.competitioncounter()
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        $('.progress-bar').css("width", (this.productservice.competitioncountervalue / 5899) * 100 + "%")
        this.ticketsold = 5899 - this.productservice.competitioncountervalue
      }, 2500);


      this.elem = document.documentElement;
      if (localStorage.ticketChoice) {
        this.productservice.positionChoices = JSON.parse(localStorage.ticketChoice)
      } else {
        localStorage.ticketChoice = []
      }



      if (localStorage.basket) {
        bucket = JSON.parse(localStorage.basket)
        console.log('let bucket')
      }

      this.productservice.basketsummarylist.subscribe(message => this.message = message)
      // this.productSubscription = this.productservice.productlistsubject.subscribe(
      //   (products: any[]) => {
      //     this.products = products;
      //   }
      // )

      this.nbrTicketToSelect = [
        { "value": 1 },
        { "value": 5 },
        { "value": 10 },
        { "value": 20 },
        { "value": 50 }
      ]


      this.productservice.getProducts().subscribe(data => this.products = data, err => console.log(err))
      // console.log(this.products.images)
    }// fin platformbrowser
  }


  // openFullscreen() {
  //   if (this.elem.requestFullscreen) {
  //     this.elem.requestFullscreen();
  //   } else if (this.elem.mozRequestFullScreen) {
  //     /* Firefox */
  //     this.elem.mozRequestFullScreen();
  //   } else if (this.elem.webkitRequestFullscreen) {
  //     /* Chrome, Safari and Opera */
  //     this.elem.webkitRequestFullscreen();
  //   } else if (this.elem.msRequestFullscreen) {
  //     /* IE/Edge */
  //     this.elem.msRequestFullscreen();
  //   }
  // }



  addfastbtn(product) {
    this.snackbar.open("Your ticket(s) has been added to basket.", '', { duration: 3000 })
    console.log(product)

    this.productservice.pushPositionTicketChoice(product)



    // Afficher le panier
    this.productservice.bucketOpened = true
    $('.overlayBucket').show()
    // Fin Afficher le panier

    if (isPlatformBrowser(this.platformId)) {
      if (!localStorage.basket) {
        bucket = []
      }
    }



    if (product.value + this.productservice.totalticket > 150) {
      console.log("error" + this.productservice.totalticket)
      alert("You have reached the limit of 150 tickets per competition")
    } else {
      if (bucket.length != 0) {
        console.log(bucket.length)
        let x = 0
        let noNewProduct = false
        bucket.forEach(el => {
          x++
          if (el.product.id == product.product.id) {
            noNewProduct = true
            el.value = el.value + product.value
          } else if (x == bucket.length && noNewProduct == false) {
            bucket.push(product)
          }
        });
        this.productservice.basketExchange(bucket)
      } else {
        bucket.push(product)
        this.productservice.basketExchange(bucket)
      }
    }
    // on descend en bas du panier lorsque l'on ajoute le ticket
    setTimeout(() => {
      var objDiv = document.getElementById("basketOverlay");
      objDiv.scrollTop = objDiv.scrollHeight;
    }, 400);

  }

  prizeDetailOpenOverlay(product) {
    console.log('fd')

    $('body').css("overflow", "hidden")
    $('.prizeDetailOverlay').show()
    this.overlay = product
    // console.log(JSON.parse(JSON.stringify(this.overlay.images)))
    if (this.overlay.images == "") {
      console.log("pas de photos")
    } else {
      if (this.overlay.images[0] === "[") {
        this.overlay.images = JSON.parse(this.overlay.images)
      }

    }
  }




  prizeDetailCloseOverlay() {
    $('.prizeDetailOverlay').hide()
    $('body').css("overflow", "")
  }

  moinsThisProduct(product) {
    console.log(product)
    if (this.quantityAddBasket != 1) {
      this.quantityAddBasket = this.quantityAddBasket - 1
      product.value = product.value - 1
      this.selection = product
    }
  }

  plusThisProduct(product) {
    console.log(product)
    this.quantityAddBasket = this.quantityAddBasket + 1
    product.value = product.value + 1
    this.selection = product
  }

  addToBasket(product) {

    this.productservice.pushPositionTicketChoice(product)

    // Afficher le panier
    this.productservice.bucketOpened = true
    $('.overlayBucket').show()
    // Fin Afficher le panier

    // verification du local storage pour eviter bug, on le vide si Empty
    if (isPlatformBrowser(this.platformId)) {
      if (!localStorage.basket) {
        bucket = []
      }
    }
    if (product.value + this.productservice.totalticket > 150) {
      console.log("error" + this.productservice.totalticket)
      alert("Vous avez atteint la limite de 150 tickets par compétition")
    } else {
      if (bucket.length != 0) {
        console.log(bucket.length)
        let x = 0
        let noNewProduct = false
        bucket.forEach(el => {
          x++
          if (el.product.id == product.product.id) {
            noNewProduct = true
            el.value = el.value + product.value
            this.prizeDetailCloseOverlay()
          } else if (x == bucket.length && noNewProduct == false) {
            bucket.push(product)
            this.prizeDetailCloseOverlay()
          }
        });
        this.productservice.basketExchange(bucket)
        this.prizeDetailCloseOverlay()
      } else {
        bucket.push(product)
        this.productservice.basketExchange(bucket)
        this.prizeDetailCloseOverlay()
      }
    }



    // on descend en bas du panier lorsque l'on ajoute le ticket


    setTimeout(() => {
      var objDiv = document.getElementById("basketOverlay");
      objDiv.scrollTop = objDiv.scrollHeight;
    }, 400);

    // if(product.value + this.productservice.totalticket > 150){
    //   console.log("error" + this.productservice.totalticket)
    //   alert("Vous avez atteint la limite de 150 tickets par compétition")
    // } else {

    //   bucket.push(product)
    //   // console.log(this.productservice.basket)
    //   this.prizeDetailCloseOverlay()
    //   // console.log(this.productservice.bucket)
    // }
  }


  openBucketEverytime() {

    var objDiv = document.getElementById("basketOverlay");
    objDiv.scrollTop = objDiv.scrollHeight;
  }



}

